<aside>
  <div class="aside-container shadow-sidebar" fxLayout="column" [ngClass]="{'collapsible': isCollapse}">

    <div class="head-menu" bb-1 fx-row-align-center fx-row-gap-12>
      <div class="icon">
        <gd-comp-icon [type]="'svg'" src="assets/icons/favicon.svg" size="24">
        </gd-comp-icon>
      </div>
      <div fx-column fx-column-gap-4>
        <ion-label color="text-1" bold-text>{{sidebarMenu.title}}</ion-label>
      </div>
      <div (click)="toggleCollapse()" fxFlex='none' [ngClass]="{'borderRight': !isCollapse}" class="back-arrow-box"
        fx-row-center-center>
        <ion-icon name="chevron-back-outline"></ion-icon>
      </div>
    </div>

    <div class="list-menu" #listmenu>
      <div class="list-section" *ngFor="let menu of sidebarMenu.menus; let menuIndex = index"
        [ngClass]="{'active': menu.label === activeMenu}">

        <ng-container *ngIf="menu.label !== 'Dashboard'">
          <ng-container *ngIf="isCollapse">
            <div class="label-box" (click)="toggleShow(menuIndex)" fxLayout='row' fxLayoutGap='10px'
              fxLayoutAlign='center' *ngIf="menu.child.length > 0">
              <ion-label large-text bold-text color='medium' fxFlex="">{{menu.label | translate}}</ion-label>
              <ion-icon fxFlex='none' name="caret-down-outline"></ion-icon>
            </div>
          </ng-container>
        </ng-container>

        <div class="list-sub" id="parent{{menuIndex}}" *ngIf="menu.child.length > 0">
          <div class="sub-box" pa-10 fx-row-align-center fx-row-gap-10
            (click)="openPage(menuIndex, grandIndex, grandsub)"
            *ngFor="let grandsub of menu.child; let grandIndex = index" [ngClass]="{'active':grandsub.activeSubMenu}">
            <gd-comp-icon [type]="'svg'" [src]="grandsub.icon" [color]="grandsub.activeSubMenu ? 'primary' : 'medium'"
              size="24"></gd-comp-icon>
            <ion-label *ngIf="isCollapse" color='dark'>{{grandsub.subMenu | translate}} <span
                *ngIf="grandsub.permission === 'userProfileApproval'"></span>
            </ion-label>
          </div>
        </div>
      </div>
    </div>
  </div>
</aside>