export const environment = {
  production: true,
  benefitSecretKey: 'BENaZrWnTpX6qBCK2TjMO1ekQE9qZPgqIJN',
  cermatihost: 'https://stg1.cermati.com/api',
  ppob: 'https://integration.greatdayhr.com/ppobdev',
  marketplace: 'https://api.greatdayhr.com/marketplacedev2',
  sass: 'https://saas.greatdayhr.com/getAPIConfig?account=',
  sass2:
    'https://saas3.dataon.com/sf6svc/SFCSunFish.cfm?PROVIDER=GetAPIConfig&STRAUTHKEY=9C1C5414B09EE2C0A7925C3FCC7CD25DBF54ECEDBB90BBFDA5C84F1F4CC660B95B6B3AFF2DFB05CC577B12A2ECD8072099BFF8D0CC7A0CAB3B985735&',
  sass3:
    'https://saas.dataon.com/sf6svc/SFCSunFish.cfm?PROVIDER=GetAPIConfig&STRAUTHKEY=9C1C5414B09EE2C0A7925C3FCC7CD25DBF54ECEDBB90BBFDA5C84F1F4CC660B95B6B3AFF2DFB05CC577B12A2ECD8072099BFF8D0CC7A0CAB3B985735&',
  benefitSupport: 'https://benefits-server-dev.greatdayhr.com',
};
