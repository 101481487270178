import { Injectable } from '@angular/core';
import { AlertDTO } from '@dto/index.dto';
import { GdCompAlertControllerService } from '@greatday/components';
import { AlertController } from '@ionic/angular';
import { AlertOptions } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private gdxAlertController: GdCompAlertControllerService,
  ) { }

  async warning(message: string, title = 'Warning') {
    const param = {
      title: this.translateService.instant(title),
      message: this.translateService.instant(message ?? 'Warning'),
      submitButton: {
        label: this.translateService.instant('OK'),
        role: 'role',
        handler: () => true,
      },
    };

    await (await this.gdxAlertController.create(param)).present();
  }

  async error(message: string, title = 'Error') {
    return new Promise(async (resolve, reject) => {
      const param = {
        title: this.translateService.instant(title),
        message: this.translateService.instant(message ?? 'Error Message'),
        submitButton: {
          label: this.translateService.instant('OK'),
          role: 'role',
          handler: () => resolve(true),
        },
      };

      await (await this.gdxAlertController.create(param)).present();
    });
  }

  async success(message?: string) {
    const param = {
      cssClass: 'alert-custom',
      title: this.translateService.instant('Success'),
      message: this.translateService.instant(message ?? 'Successfully'),
      submitButton: {
        label: this.translateService.instant('OK'),
        role: 'role',
        handler: () => true,
      },
    };

    const alert = await this.gdxAlertController.create(param);
    await alert.present();
    await alert.onDidDismiss();
  }

  async errorResponse(message?: any) {
    const param = {
      cssClass: 'alert-custom',
      header: this.translateService.instant('Error Message'),
      subHeader: message.error.message,
      buttons: ['Ok'],
    };
    await (await this.gdxAlertController.create(param)).present();
  }

  async presentAlertConfirm(
    message: string,
    labelCancel: string = 'Cancel',
    labelOkey: string = 'Ok',
    title: string = '',
  ) {
    return new Promise(async (resolve) => {
      const alert = await this.gdxAlertController.create({
        title: title ? this.translateService.instant(title) : this.translateService.instant('Confirm!'),
        message: message ? this.translateService.instant(message) : message,

        cancelButton: {
          label: this.translateService.instant(labelCancel),
          role: 'cancel',
          handler: () => {
            resolve(false);
          },
        },
        submitButton: {
          label: this.translateService.instant(labelOkey),
          role: 'role',
          handler: () => {
            resolve(true);
          },
        },
      });

      await alert.present();
    });
  }

  confirmationCustom(data: AlertDTO, V2 = false) {
    return new Promise(async (resolve, reject) => {
      const param = {
        cssClass: V2 ? 'alert-confirmV2' : 'alert-confirm',
        header: data.header ? this.translateService.instant(data.header) : '',
        message: data.message ? this.translateService.instant(data.message) : '',
        buttons: [
          {
            text: this.translateService.instant(data.btnNo ?? 'No'),
            cssClass: 'btn-no',
            handler: () => {
              resolve(false);
            },
          },
          {
            text: this.translateService.instant(data.btnYes ?? 'Yes'),
            cssClass: 'btn-yes',
            handler: () => {
              resolve(true);
            },
          },
        ],
      };
      await (await this.alertCtrl.create(param)).present();
    });
  }

  async forceUpdate(message: string) {
    return new Promise(async (resolve, reject) => {
      const param: AlertOptions = {
        cssClass: 'alert-confirmV2',
        header: this.translateService.instant('Information'),
        message,
        backdropDismiss: false,
        buttons: [
          {
            text: this.translateService.instant('Update Now'),
            role: 'yes',
            cssClass: 'btn-yes',
            handler: () => {
              resolve(true);
            },
          },
        ],
      };
      await (await this.alertCtrl.create(param)).present();
    });
  }

  async loginFailed(message: string) {
    return new Promise(async (resolve, reject) => {
      const param: AlertOptions = {
        cssClass: 'alert-confirmV2',
        // header: this.translateService.instant('Information'),
        message: `
          <div class="flex-col items-center">
            <img src="assets/illustration/not-found.svg" class="w-40 m-1"></img>
            <ion-label class="font-bold text-xl">${this.translateService.instant('Login Failed')}</ion-label>
            <ion-label class="text-base">${this.translateService.instant(message)}</ion-label>
          </div>
        `,
        backdropDismiss: false,
        buttons: [
          {
            text: this.translateService.instant('Try to login again'),
            role: 'no',
            cssClass: 'btn-no',
            handler: () => {
              resolve(false);
            },
          },
          {
            text: this.translateService.instant('Open Customer Service'),
            role: 'yes',
            cssClass: 'btn-yes',
            handler: () => {
              resolve(true);
            },
          },
        ],
      };
      await (await this.alertCtrl.create(param)).present();
    });
  }

  async invalidAccessTOken() {
    return new Promise(async (resolve, reject) => {
      const param: AlertOptions = {
        cssClass: 'alert-confirmV2',
        message: `
        <div class="flex-col items-center">
          <img src="assets/illustration/not-found.svg" class="w-40 m-1"></img>
          <ion-label class="font-bold text-xl">${this.translateService.instant('Your session has expired')}</ion-label>
          <ion-label class="text-base">${this.translateService.instant(
          'You will be directed to the login page to re-login',
        )}</ion-label>
        </div>
      `,
        backdropDismiss: false,
        buttons: [
          {
            text: this.translateService.instant('Go Now'),
            role: 'yes',
            cssClass: 'btn-yes',
            handler: () => {
              resolve(true);
            },
          },
        ],
      };
      await (await this.alertCtrl.create(param)).present();
    });
  }
}
