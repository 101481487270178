import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AppBreadcrumbComponentModule } from '@components/app-breadcrumb/app-breadcrumb.component.module';
import { NavbarComponent } from '@components/navbar/navbar.component';
import { SidebarComponent } from '@components/sidebar/sidebar.component';
import { MainLayoutComponent } from './main-layout.component';
import { GdCompIconModule, GdCompLoadingModule } from '@greatday/components';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FlexLayoutModule,
    TranslateModule,
    AppBreadcrumbComponentModule,
    GdCompIconModule,
    GdCompLoadingModule,
  ],
  declarations: [MainLayoutComponent, NavbarComponent, SidebarComponent],
  exports: [MainLayoutComponent, NavbarComponent, SidebarComponent],
})
export class MainLayoutModule {}
