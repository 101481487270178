import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  isShowSpinner = false;
  isShowSpinnerArr$ = new BehaviorSubject<Map<number, boolean>>(new Map<number, boolean>());
  constructor(private loadingCtrl: LoadingController) {}

  async showSpinner(spinnerId?: number) {
    if (!spinnerId) {
      this.isShowSpinner = true;
    } else {
      this.isShowSpinnerArr$.next(this.isShowSpinnerArr$.value.set(spinnerId, true));
    }

    this.loadingCtrl
      .create({
        spinner: 'lines',
        translucent: true,
        cssClass: 'default-spinner custom-loading',
      })
      .then((loader) => {
        loader.present().then(() => {
          if (!spinnerId) {
            if (!this.isShowSpinner) {
              loader.dismiss();
            }
          } else {
            if (!this.isShowSpinnerArr$.value.get(spinnerId)) {
              loader.dismiss();
            }

            /**
             * This implementation can help with spinner stuck issue
             */
            this.isShowSpinnerArr$.subscribe((map) => {
              if (map.get(spinnerId) !== null && map.get(spinnerId) !== undefined && map.get(spinnerId) === false) {
                loader.dismiss();
              }
            })
          }
        });
      });
  }

  async showSpinnerWithMessage(message?: string) {
    this.isShowSpinner = true;
    const defaultMessage = 'Please wait...';
    this.loadingCtrl
      .create({
        spinner: 'lines',
        translucent: true,
        cssClass: 'custom-class custom-loading',
        message: message ? message : defaultMessage,
      })
      .then((loader) => {
        loader.present().then(() => {
          if (!this.isShowSpinner) {
            loader.dismiss();
          }
        });
      });
  }

  async hideSpinner(spinnerId?: number) {
    if (!spinnerId) {
      this.isShowSpinner = false;
    } else {
      this.isShowSpinnerArr$.next(this.isShowSpinnerArr$.value.set(spinnerId, false));
    }

    this.loadingCtrl.getTop().then((loader) => {
      if (loader) {
        loader.dismiss();
      }
    });
  }

  async isShow() {
    return this.isShowSpinner;
  }
}
