import { Injectable } from '@angular/core';
import { IBenefitLoginResponse } from '@dto/index.dto';
import { BaseStorageService } from '@storage/base/base-storage.service';
import { StoragePreferenceEnum } from '../constant/storage.constant';

@Injectable({
  providedIn: 'root',
})
export class AuthStorageService {
  constructor(private baseStorageService: BaseStorageService) { }

  async getBenefitAuth() {
    return await this.baseStorageService.getGreatDayStorage<IBenefitLoginResponse>(
      StoragePreferenceEnum.BenefitAuthSupport,
    );
  }

  async setBenefitSupportAuth(auth: IBenefitLoginResponse) {
    await this.baseStorageService.setGreatDayStorage(StoragePreferenceEnum.BenefitAuthSupport, auth);
  }
}
