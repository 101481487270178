import { CurrencyPipe, DatePipe, TitleCasePipe } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PipesModule } from '@pipes/pipes.module';
import { SFFormatDatePipe } from '@pipes/sf-formate-date.pipes';
import { BaseApiInterceptor } from '@remotes/interceptor/base-api.interceptor';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LottieModule } from 'ngx-lottie';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutModule } from './shared/layouts/main-layout/main-layout.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MainLayoutModule,
    HttpClientModule,
    PipesModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
    }),
    LottieModule.forRoot({
      player: playerFactory,
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BaseApiInterceptor, multi: true },
    { provide: TitleCasePipe, useClass: TitleCasePipe },
    { provide: SFFormatDatePipe, useClass: SFFormatDatePipe },
    CurrencyPipe,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
