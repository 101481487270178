<nav class="ion-padding">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="logo-box" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center start" tappable
      (click)="backToHome();">
      <img tappable class="logo" fxFlex="none" src="assets/images/logo/benefit-logo-invert.svg" alt="">
    </div>
    <div class="option-box" id="navbar-profile" fxFlex="none" [ngClass]="{'option-active': toggleHeaderPopover}">
      <div id="navbar-profile" class="profil-box" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center"
        tappable (click)="profileToggle()" #profile>
        <ion-avatar>
          <img id="navbar-profile" src="assets/images/empty-state/avatar-not-found.svg"
            onerror="this.onerror = null; this.src='assets/images/logo-greatday.png';" alt="icon" />
        </ion-avatar>
        <div id="navbar-profile" fxFlex="" fxLayout="column" class="option-section">
          <ion-label id="navbar-profile" class="name text-md">{{"Hi" | translate}},
            {{ userData? userData.fullName : null }}</ion-label>
          <!-- <ion-label id="navbar-profile" ellipsis class="job text-xs" color="text-1">{{ 'Dukun Komputer' }}</ion-label> -->
        </div>
      </div>
      <div *ngIf="toggleHeaderPopover" [ngClass]="{'option-active': toggleHeaderPopover}" class="option-popover" pb-12>
        <div class="list-box show-content">
          <ng-container *ngFor="let item of menuPopover; last as isLast">
            <div class="list-section" tappable (click)="openPage(item.id);" fxLayout="row" fxLayoutGap="10px"
              fxLayoutAlign="start center">
              <gd-comp-icon [type]="'svg'" [color]="'medium'" [name]="item.iconUrl"></gd-comp-icon>
              <p small-text>{{item.label | translate}}</p>
            </div>
          </ng-container>

          <div class="list-section tappable" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center"
            (click)="openLanguage()">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <gd-comp-icon [type]="'svg'" [color]="'medium'" [name]="'icon-translate'"></gd-comp-icon>
              <p small-text>{{"Language" | translate}}</p>
            </div>
          </div>
          <div class="list-section tappable" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center"
            (click)="openSwitchAccount()" *ngIf="countries.length >  1">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <gd-comp-icon [type]="'svg'" [color]="'medium'" [name]="'icon-users'"></gd-comp-icon>
              <div fxLayout="column">
                <ion-label class="text-xs" color="text-1">{{ 'Switch Account' }}</ion-label>
                <ion-label class="text-xs" color="text-1">{{'Currently : '}} {{ country.name }}</ion-label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="breadcrumb-header">
    <app-breadcrumb></app-breadcrumb>
  </div>
</nav>