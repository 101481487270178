import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { PagingDTO, RequestAPI, UpdateApprovalUserProfile } from '@dto/index.dto';
import { SFFormatDatePipe } from '@pipes/sf-formate-date.pipes';
import { BenefitsApi } from '@remotes/apis/benefits.api';
import { HandlingService } from '@services/lib/handling/handling.service';
import { IntegerService } from '@services/lib/integer/integer.service';
import { SpinnerService } from '@services/lib/spinner/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class UserProfileApprovalService {
  constructor(
    private benefitsApi: BenefitsApi,
    private spinnerService: SpinnerService,
    private sfFormatDate: SFFormatDatePipe,
    private handlingService: HandlingService,
    private integerService: IntegerService,
    private currencyPipe: CurrencyPipe,
  ) {}

  async listApprovalInternalPlan(paging: PagingDTO, params?: any) {
    try {
      const request: RequestAPI = {
        model: 'Employee',
        func: 'listApprovalInternalPlan',
        queryParams: {
          ...paging,
          ...params,
        },
      };
      const data = await this.benefitsApi.get(request);
      data.data.map((item: any) => {
        item.requestBy = item.requestBy ?? '-';
        item.joinDate = item.joinDate ? this.sfFormatDate.transform(item.joinDate, 'DD-MM-YYYYY') : '-';
        item.requestDate = item.requestDate ? this.sfFormatDate.transform(item.requestDate, 'DD-MM-YYYYY') : '-';
        item.cta = [
          {
            label: '',
            iconUrl: 'assets/icons/icon-feather/icon-info.svg',
            buttonType: 'limit',
            variant: 'primary',
            fill: 'clear',
            customFunction: true,
          },
        ];
      });
      return data;
    } catch (error) {
      await this.spinnerService.hideSpinner();
      this.handlingService.errorResponse(error);
    }
  }

  async compareApprovalInternalPlan(params?: any) {
    try {
      const request: RequestAPI = {
        model: 'Employee',
        func: 'compareApprovalInternalPlan',
        queryParams: params,
      };
      const data = await this.benefitsApi.get(request);

      const formatGender = (gender: number) => gender === 0 ? 'Female' : gender === 1 ? 'Male' : '-';
      const formatDate = (date: any) => this.sfFormatDate.transform(date, 'DD-MM-YYYYY');

      if (data.data.requestType === 1) {
        const { currentData, requestDetail } = data.data;

        currentData.joinDate = formatDate(currentData.joinDate);
        currentData.resignDate = formatDate(currentData.resignDate);
        currentData.birthDate = formatDate(currentData.birthDate);
        currentData.gender = formatGender(currentData.gender);

        requestDetail.joinDate = formatDate(requestDetail.joinDate);
        requestDetail.resignDate = formatDate(requestDetail.resignDate);
        requestDetail.birthDate = formatDate(requestDetail.birthDate);
        requestDetail.gender = formatGender(requestDetail.gender);

      } else {
        const { countryCode } = params;

        const formatSalary = (salary: number, country: string) => {

          if (!isNaN(salary)) {
            if (country === 'ID') {
              return this.integerService.formatRupiah(salary);
            } else if (country === 'MY') {
              return this.currencyPipe.transform(salary, 'Rm', 'symbol', '1.0-0');
            } else if (country === 'PH') {
              return this.currencyPipe.transform(salary, 'PHP', 'symbol', '1.0-0');
            } else if (country === 'TH') {
              return this.integerService.formatBaht(salary + '');
            }
          }
          return salary || '-';
        };

        const { currentData, requestData } = data.data;

        currentData.basicSalary = formatSalary(currentData.basicSalary, countryCode);
        requestData.basicSalary = formatSalary(requestData.basicSalary, countryCode);

        const formatAllocation = (allocation: number, allocationType: string, country: string) => {
          if (allocationType === 'percent') {
            return `${allocation}%`;
          }
          return formatSalary(allocation, country);
        };

        currentData.allocation = formatAllocation(currentData.allocation, currentData.allocationType, countryCode);
        requestData.allocation = formatAllocation(requestData.allocation, requestData.allocationType, countryCode);
      }

      return data;
    } catch (e: any) {
      await this.spinnerService.hideSpinner();
      this.handlingService.errorResponse(e.error ? e : { error: e.toString() } );
    }
  }

  async updateLimitSetupEmployee(params: UpdateApprovalUserProfile) {
    try {
      const request: RequestAPI = {
        model: 'Employee',
        func: 'updateApprovalInternalPlan',
        params,
      };
      const data = await this.benefitsApi.post(request);
      return data;
    } catch (error) {
      await this.spinnerService.hideSpinner();
      this.handlingService.errorResponse(error);
    }
  }

  async sidebarBadge() {
    try {
      const request: RequestAPI = {
        model: 'Employee',
        func: 'sidebarBadge',
      };
      const data = await this.benefitsApi.get(request);
      return data;
    } catch (error) {
      await this.spinnerService.hideSpinner();
      this.handlingService.errorResponse(error);
    }
  }
}
