import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StringService {
  constructor() {}
  camelize(str: string) {
    return str.replace(/([-_\s][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', '').replace(' ', ''),
    );
  }

  normalize(str: string) {
    const result = str.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }

  onlyNumeric(str: string | number) {
    if (str !== undefined && str !== null) {
      if (typeof str === 'number') {
        str = str.toString();
      }

      const result = str.replace(/[^0-9]/g, '');
      if (result !== '') {
        return result;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  regexOnlyNumeric(str: string) {
    return str.replace(/[^0-9 ]/g, '');
  }

  capitalEach(str: string) {
    const lowerStr = str.toLowerCase();
    return lowerStr.charAt(0).toUpperCase() + lowerStr.slice(1);
  }
}
