import { Injectable } from '@angular/core';
import { FormDataUpload, RequestAPI, RequestBuilder } from '@dto/index.dto';
import { BaseApiService } from '@remotes/base/base-api.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BenefitsApi {
  protected model = 'benefitsService';
  protected service = 'benefitSupport';

  constructor(private baseApi: BaseApiService) {}

  async get(request: RequestAPI): Promise<any> {
    const method = 'GET';
    const builder: RequestBuilder = {
      method,
      func: request.func,
      params: request.params,
      urlParams: request.queryParams,
      model: request.model ?? this.model,
      service: this.service,
    };

    const url = await this.baseApi.urlBuilder(builder);
    return await firstValueFrom(this.baseApi.request({ method, url, params: request.params }));
  }

  async getBlob(request: RequestAPI): Promise<any> {
    const method = 'GET';
    const builder: RequestBuilder = {
      method,
      func: request.func,
      params: request.params,
      urlParams: request.queryParams,
      model: request.model ?? this.model,
      service: this.service,
    };

    const url = await this.baseApi.urlBuilder(builder);
    return await firstValueFrom(this.baseApi.requestBlob({ method, url, params: request.params }));
  }

  async post(request: RequestAPI): Promise<any> {
    const method = 'POST';
    const builder: RequestBuilder = {
      method,
      func: request.func,
      params: request.params,
      urlParams: request.queryParams,
      model: request.model ?? this.model,
      service: this.service,
    };

    const url = await this.baseApi.urlBuilder(builder);
    return await firstValueFrom(this.baseApi.request({method, url, params: request.params}));
  }

  async postBlob(request: RequestAPI): Promise<any> {
    const method = 'POST';
    const builder: RequestBuilder = {
      method,
      func: request.func,
      params: request.params,
      urlParams: request.queryParams,
      model: request.model ?? this.model,
      service: this.service,
    };

    const url = await this.baseApi.urlBuilder(builder);
    return await firstValueFrom(this.baseApi.requestBlob({ method, url, params: request.params }));
  }

  async postUpload(request: RequestAPI, formDataUpload?: FormDataUpload[]): Promise<any> {
    const method = 'POST';
    const builder: RequestBuilder = {
      method,
      func: request.func,
      params: request.params,
      urlParams: request.queryParams,
      model: request.model ?? this.model,
      service: this.service,
    };

    const url = await this.baseApi.urlBuilder(builder);
    return await firstValueFrom(this.baseApi.requestUpload({ method, url, params: request.params }, formDataUpload));
  }

  async put(request: RequestAPI): Promise<any> {
    const method = 'PUT';
    const builder: RequestBuilder = {
      method,
      func: request.func,
      params: request.params,
      urlParams: request.queryParams,
      model: request.model ?? this.model,
      service: this.service,
    };

    const url = await this.baseApi.urlBuilder(builder);
    return await firstValueFrom(this.baseApi.request({ method, url, params: request.params }));
  }

  async delete(request: RequestAPI): Promise<any> {
    const method = 'DELETE';
    const builder: RequestBuilder = {
      method,
      func: request.func,
      params: request.params,
      urlParams: request.queryParams,
      model: request.model ?? this.model,
      service: this.service,
    };

    const url = await this.baseApi.urlBuilder(builder);
    return await firstValueFrom(this.baseApi.request({ method, url, params: request.params }));
  }

  async getObservable(request: RequestAPI): Promise<string> {
    const method = 'GET';
    const builder: RequestBuilder = {
      method,
      func: request.func,
      params: request.params,
      urlParams: request.queryParams,
      model: request.model ?? this.model,
      service: this.service,
    };

    const url = await this.baseApi.urlBuilder(builder);
    return url;
  }
}
