import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { MAIN_ROUTE } from '@constants/index.constant';
import { NavController } from '@ionic/angular';

export interface GetParams<T> {
  params: T;
}

interface NavigationPropsDTO {
  params?: any;
  queryParams?: any;
}
@Injectable({
  providedIn: 'root',
})
export class NavigationService {

  navigationExtras: NavigationExtras = {
    state: undefined,
  };

  public paramTmp: any = '';

  constructor(private navCtrl: NavController, private router: Router) {}
  
  setRoot(page: string, params?: any) {
    const navigationExtras: NavigationExtras = {
      state: {
        params
      }
    };

    this.paramTmp = navigationExtras;

    return this.navCtrl.navigateRoot([page], navigationExtras);
  }

  navigateForward(route: string, props?: NavigationPropsDTO) {
    let navigationExtras: NavigationExtras = {};
    if (props) {
      if (props.params) {
        navigationExtras.state = props.params;
      }

      if (props.queryParams) {
        navigationExtras.queryParams = props.queryParams;
      }
    }
    this.navCtrl.navigateForward([route], navigationExtras);
  }

  navigateWithReplace(route: string, state?: any) {
    this.navigationExtras.replaceUrl = true;
    this.navigationExtras.state = state;
    this.router.navigateByUrl(route, this.navigationExtras);
  }

  navigateRoot(page: string, params?: any) {
    const navigationExtras: NavigationExtras = {
      state: params,
    };
    this.navCtrl.navigateRoot([page], navigationExtras);
  }

  navigateBack(page: string, params?: any) {
    const navigationExtras: NavigationExtras = {
      state: params,
    };
    this.navCtrl.navigateBack([page], navigationExtras);
  }

  getState<T>() {
    const currentNavigate = this.router.getCurrentNavigation();
    if (currentNavigate && currentNavigate.extras) {
      const currentNavigation = this.router.getCurrentNavigation();
      if (currentNavigation) {
        const state = currentNavigation.extras.state;
        return (state as T) ?? null;
      } else {
        return null;
      }
    }
    return null;
  }

  mainPage() {
    this.navigateWithReplace(MAIN_ROUTE);
  }

  mainGreatdayPage() {
    window.location.href = 'https://app.greatdayhr.com/';
  }
}
