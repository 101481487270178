import { Component, ElementRef, Host, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ECountryCode, SidebarMenu, SideMenu } from '@constants/index.constant';
import { IBaseMenu, IListType } from '@dto/index.dto';
import { AuthService } from '@services/bloc/auth/auth.service';
import { MemberService } from '@services/bloc/company/member/member.service';
import { UserProfileApprovalService } from '@services/bloc/company/user-profile-approval/user-profile-approval.service';
import { NavigationService } from '@services/lib/navigation/navigation.service';
import { StringService } from '@services/lib/string/string.service';
import { BaseStorageService } from '@storage/base/base-storage.service';
import { StoragePreferenceEnum } from '@storage/constant/storage.constant';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  isCollapse = false;
  activeMenu = '';
  // countBadge: any;
  public showSideMenu = 0;
  public toggleSidemenu = true;
  public sidebarMenu: SidebarMenu = {
    title: 'Benefit Support',
    menus: [],
  };
  constructor(
    @Host() public host: ElementRef,
    private navigationService: NavigationService,
    private stringService: StringService,
    private memberService: MemberService,
    private baseStorageService: BaseStorageService,
    private router: Router,
    private userProfileApprovalService: UserProfileApprovalService,
    private authService: AuthService,
  ) { }

  async ngOnInit() {
    this.toggleCollapse(false);
    this.handleMenu();
    // this.getSidebarBadge();
  }

  // async getSidebarBadge() {
  //   const data = await this.userProfileApprovalService.sidebarBadge();
  //   if (data) {
  //     this.countBadge = data.data.approvalInternalPlan;
  //   }
  // }

  async handleMenu() {
    this.host.nativeElement.classList.add('active-sidebar');
    this.host.nativeElement.classList.remove('minimize-sidebar');
    const header = document.querySelector('app-navbar');
    if (header) {
      header.classList.add('show-breadcrumb');
    }
    this.sidebarMenu.menus = await this.handleMenuMaping();
    this.sidebarMenu.menus.forEach((menu) => {
      menu.child.forEach((item: IBaseMenu) => {
        item.sideType = this.stringService.normalize(item.sideType);
        if (item.route === this.router.url) {
          item.activeSubMenu = true;
        }
        if (item.activeSubMenu) {
          this.activeMenu = item.sideType;
        }
      });
    });
  }

  async handleMenuMaping() {
    const sideMenuByRole = await this.filterByRole();
    const sideMenuByExcludeReviewer = await this.excludeReviewer(sideMenuByRole);
    const sideMenuByCountry = await this.filterByCountry(sideMenuByExcludeReviewer);
    const newMenu = this.groupBy(sideMenuByCountry, 'sideType');
    const results = [];
    for (const key in newMenu) {
      results.push({
        label: this.stringService.normalize(key),
        child: newMenu[key],
      });
    }
    return results;
  }

  async filterByRole() {
    const role = await this.memberService.listRolePermissionUser();
    await this.baseStorageService.setGreatDayStorage(StoragePreferenceEnum.UserRoles, role);
    if (role) {
      const roleFilter = role.filter((item: any) => item.role.length > 0);
      const roleMap = roleFilter.map((item: any) => item.permission);
      const sideMenu = SideMenu.filter((menu: IBaseMenu) => {
        const filtered = roleMap.filter((item: string) => menu.permission === item || menu.permission === 'noAuth');
        if (filtered.length > 0) {
          return true;
        }
        return false;
      });
      return sideMenu;
    }
    return [];
  }

  async excludeReviewer(sideMenu: IBaseMenu[]) {
    const storage: any = await this.baseStorageService.getGreatDayStorage(StoragePreferenceEnum.BenefitAuthSupport);
    let overviewReviewer: any;
    let transactionHistoryReviewer: any;
    let billingReviewer: any;
    if (storage.userData.rolePermission) {
      overviewReviewer = storage.userData.rolePermission[0].isReviewer ?? false;
      transactionHistoryReviewer = storage.userData.rolePermission[1].isReviewer ?? false;
      billingReviewer = storage.userData.rolePermission[5].isReviewer ?? false;
    }
    else {
      const { data } = await this.authService.loginData();
      overviewReviewer = data.userData.rolePermission[0].isReviewer ?? false;
      transactionHistoryReviewer = data.userData.rolePermission[1].isReviewer ?? false;
      billingReviewer = data.userData.rolePermission[5].isReviewer ?? false;
    }
    if (overviewReviewer) {
      sideMenu = sideMenu.filter((data: any) => data = overviewReviewer ? data.permission !== 'overview' : data);
    }
    if (transactionHistoryReviewer) {
      sideMenu = sideMenu.filter((data: any) => data = transactionHistoryReviewer ? data.permission !== 'transactionHistory' : data);
    }
    if (billingReviewer) {
      sideMenu = sideMenu.filter((data: any) => data = billingReviewer ? data.subMenu !== 'Management' : data);
    }
    return sideMenu;
  }

  async filterByCountry(sideMenu: IBaseMenu[]) {
    // Get the user's selected country from storage
    const countries: IListType[] = await this.baseStorageService.getGreatDayStorage(
      StoragePreferenceEnum.CurrentCountry,
    );

    // If no country information is available, return the original menu
    if (!countries) {
      return sideMenu;
    }

    // Extract the country code from the first item in the countries array
    const countryCode = countries[0]?.value;

    // Define exclusion menus for different countries
    const excludeMenus: Record<string, string[]> = {
      [ECountryCode.MY]: [
        'Topup and Bills',
        'Payout',
        'Payout Type',
        'Cashadvance Balance',
        'Benefit Collections',
        'E2pay Overview',
        'RJB Overview',
        'Repayment Bill',
        'Repayment History',
      ],
      [ECountryCode.PH]: [
        'Payout',
        'Payout Type',
        'Cashadvance Balance',
        'Benefit Collections',
        'E2pay Overview',
        'RJB Overview',
        'Repayment Bill',
        'Repayment History',
        'Reset Pin',
        'Greatday Leads',
      ],
      [ECountryCode.TH]: [
        'E2pay Overview',
        'RJB Overview',
        'Benefit Collections',
        'Payout',
        'Payout Type',
        'Repayment Bill',
        'Repayment History',
        'Greatday Leads',
        'Monitoring',
        'Pending Payment',
        'User Verification',
        'User Verification Automatic'
      ]
    };

    // Initialize an empty array for menus to include
    let includeMenu: IBaseMenu[] = [];

    // If the country code is Thailand (TH), include 'Digital Goods Balance'
    // (new enhancement) Hide Digital Goods Balance for TH account (TCK2411-0978693)
    // if (countryCode === ECountryCode.TH) {
    //   includeMenu.push({
    //     subMenu: 'Digital Goods Balance',
    //     sideType: 'overview',
    //     activeSubMenu: false,
    //     route: '/digital-goods-balance',
    //     permission: 'overview',
    //   });
    // }

    // Get the list of menus to exclude based on the user's country
    const excludeMenu = excludeMenus[countryCode] || [];

    // Filter the original menu to exclude the specified items
    const filteredMenu = sideMenu.filter((item) => !excludeMenu.includes(item.subMenu));

    // Concatenate the menus to include with the filtered menu
    const finalMenu = [...filteredMenu, ...includeMenu];

    // Return the final menu
    return finalMenu;
  }

  groupBy(items: any[], key: string) {
    return items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {},
    );
  }

  toggleCollapse(firstLoad?: boolean) {
    if (!firstLoad) {
      this.isCollapse = !this.isCollapse;
    }
    const sidebar = document.querySelector('app-navbar');
    if (sidebar !== null) {
      if (!this.isCollapse) {
        this.host.nativeElement.classList.add('minimize-sidebar');
        sidebar.classList.add('full-breadcrumb');
      } else {
        this.host.nativeElement.classList.remove('minimize-sidebar');
        sidebar.classList.remove('full-breadcrumb');
      }
    }
  }

  async openPage(parentId: any, childId: any, item: IBaseMenu) {
    await this.getActiveMenu(parentId, childId);
    this.navigationService.navigateForward(item.route);
  }

  async getActiveMenu(parentId: any, childId: any) {
    this.sidebarMenu.menus.forEach((parent, parentIndex) => {
      parent.child.forEach((child, childIndex) => {
        if (parentIndex === parentId && childIndex === childId) {
          child.activeSubMenu = true;
          const nameCapitalized = this.stringService.normalize(child.subMenu);
          this.activeMenu = nameCapitalized;
        } else {
          child.activeSubMenu = false;
        }
      });
    });
  }

  toggleShow(e: any) {
    const el = document.getElementById('parent' + e);
    if (el !== null) {
      if (el.classList.contains('hide')) {
        el.classList.remove('hide');
      } else {
        el.classList.add('hide');
      }
    }
  }
}
