import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare let require: any;
const CryptoJS = require('crypto-js');

@Injectable({
  providedIn: 'root',
})
export class EncryptService {
  constructor() {}

  encrypt(pin: string) {
    return CryptoJS.AES.encrypt(pin, environment.benefitSecretKey).toString();
  }

  decrypt(encryptedPin: string) {
    const bytes = CryptoJS.AES.decrypt(encryptedPin, environment.benefitSecretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
}
