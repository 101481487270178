import { HttpClient, HttpEvent, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FormDataUpload,
  RequestBlob,
  RequestBuilder,
  RequestData,
  RequestFormDataUpload,
  RequestUpload
} from '@dto/index.dto';
import { BaseUrlTypeEnum } from '@remotes/base-url/base-url.enum';
import { BaseUrlService } from '@remotes/base-url/base-url.service';
import { TokenService } from '@remotes/token/token.service';
import { Observable } from 'rxjs/internal/Observable';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BaseApiService {
  constructor(private http: HttpClient, private baseUrlService: BaseUrlService, private tokenService: TokenService) { }

  public async baseUrlBuilder(baseUrlType: any): Promise<string> {
    return await this.baseUrlService.getBaseUrl(baseUrlType);
  }

  public async urlBuilder(data: RequestBuilder): Promise<string> {
    let host: string;
    if (data.service === 'marketplace') {
      host = await this.baseUrlBuilder(BaseUrlTypeEnum.MARKETPLACE);
    } else if (data.service === 'gdtransfer') {
      host = await this.baseUrlBuilder(BaseUrlTypeEnum.GDTRANSFER);
    } else if (data.service === 'payroll') {
      host = await this.baseUrlBuilder(BaseUrlTypeEnum.PAYROLL);
    } else if (data.service === 'tsmarketplace') {
      host = await this.baseUrlBuilder(BaseUrlTypeEnum.TSMARKETPLACE);
    } else if (data.service === 'cermati') {
      host = await this.baseUrlBuilder(BaseUrlTypeEnum.TSMARKETPLACE);
    } else if (data.service === 'ppob') {
      host = await this.baseUrlBuilder(BaseUrlTypeEnum.PPOB);
    } else if (data.service === 'local') {
      host = await this.baseUrlBuilder(BaseUrlTypeEnum.LOCAL3000);
    } else if (data.service === 'benefitSupport') {
      host = await this.baseUrlBuilder(BaseUrlTypeEnum.BENEFITSSUPPORT);
    } else {
      host = await this.baseUrlBuilder(BaseUrlTypeEnum.GDSERVER);
    }
    return `${host}/${data.model}/${data.func}?${new URLSearchParams(this.jsonToUrl(data.urlParams, false))}`;
  }

  public request<T>(data: RequestData): Observable<T> {
    const headers = new HttpHeaders({
      ...(data?.timeout && { timeout: `${data.timeout}` }),
      isNest: 'true',
      'Content-Type': 'application/json',
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
      }),
      body: data.params,
    };

    switch (data.method) {
      case 'GET':
        return this.http.get<T>(data.url, { headers });
      case 'POST':
        return this.http.post<T>(data.url, data.params, { headers });
      case 'DELETE':
        return this.http.delete<T>(data.url, httpOptions);
      case 'PUT':
        return this.http.put<T>(data.url, data.params, { headers });
      default:
        return this.http.get<T>(data.url);
    }
  }

  public requestBlob<T>(data: RequestBlob): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      ...(data?.timeout && { timeout: `${data.timeout}` }),
      isNest: 'true',
      'Content-Type': 'application/json',
    });

    switch (data.method) {
      case 'GET':
        return this.http.get<T>(data.url, { headers, observe: 'response', responseType: 'blob' as 'json' });
      case 'POST':
        return this.http.post<T>(data.url, data.params, {
          headers,
          observe: 'response',
          responseType: 'blob' as 'json',
        });
      default:
        return this.http.get<T>(data.url, { headers, observe: 'response' });
    }
  }

  public requestUpload<T>(data: RequestUpload, formDataUpload?: FormDataUpload[]): Observable<T> {
    const timeout = `${5 * 60 * 1000}`;
    const headers: HttpHeaders = new HttpHeaders({
      timeout,
      isNest: 'true',
      'Allow-Control-Allow-Origin': '*',
    });

    const formData = new FormData();
    if (formDataUpload) {
      for (const body of formDataUpload) {
        if (body.fileName) {
          formData.append(body.name, body.value, body.fileName);
        } else {
          formData.append(body.name, body.value);
        }
      }
    }

    return this.http.post<T>(data.url, formData, { headers });
  }

  public requestMethod<T>(method: string, url: string, headers: HttpHeaders, params: any): Observable<T> {
    switch (method) {
      case 'GET':
        return this.http.get<T>(url, { headers });
      case 'POST':
        return this.http.post<T>(url, params, { headers });
      default:
        return this.http.get<T>(url);
    }
  }

  private jsonToUrl(obj: any, parent: any): string {
    const parts = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        parts.push(this.parseParam(key, obj[key], parent));
      }
    }
    return parts.join('&');
  }

  private parseParam(key: string, value: any, parent: string): string {
    const processedKey = parent ? parent + '[' + key + ']' : key;
    if (value && ((typeof value as string) === 'object' || Array.isArray(value))) {
      return this.jsonToUrl(value, processedKey);
    }
    return processedKey + '=' + value;
  }

  public requestJson<T>(url: string) {
    return firstValueFrom(this.http.get<T>(url));
  }

  public requestUploadWithProgress(
    method: string,
    url: string,
    bodies: RequestFormDataUpload[] | undefined,
  ): Observable<HttpEvent<any>> {
    // Make sure tokenService.token value assigned first
    this.tokenService.useTokenService();
    setTimeout(() => {
    }, 500);

    const timeout = `${5 * 60 * 1000}`;
    const headers: HttpHeaders = new HttpHeaders({ timeout, isUpload: 'true' }).set(
      'Authorization',
      this.tokenService.token.id,
    );

    const formData = new FormData();
    if (bodies) {
      for (const body of bodies) {
        if (body.fileName) {
          formData.append(body.name, body.value, body.fileName);
        } else {
          formData.append(body.name, body.value);
        }
      }
    }

    const request = new HttpRequest(method, url, formData, { headers, reportProgress: true });
    return this.http.request<HttpEvent<any>>(request);
  }

  public requestBlobWithProgress(method: string, url: string, params?: any): Observable<HttpEvent<any>> {
    const headers: HttpHeaders = new HttpHeaders({ timeout: `${5 * 60 * 1000}`, isUpload: 'true' })
      .set('Authorization', this.tokenService.token.id)
      .append('Content-Type', 'application/json');

    let request: HttpRequest<any>;
    switch (method) {
      case 'GET':
        request = new HttpRequest(method, url, { headers, reportProgress: true, responseType: 'blob' as 'json' });
        return this.http.request<HttpEvent<any>>(request);
      case 'POST':
        request = new HttpRequest(method, url, params, {
          headers,
          reportProgress: true,
          responseType: 'blob' as 'json',
        });
        return this.http.request<HttpEvent<any>>(request);
      default:
        request = new HttpRequest(method, url, { headers, reportProgress: true, responseType: 'blob' as 'json' });
        return this.http.request<HttpEvent<any>>(request);
    }
  }
}
