<div hide-mobile class="app-breadcrumb" pl-20 pa-16 pb-16>
  <div class="home-box" (click)="toHome()" fxLayout='row' fxLayoutGap='5px'>
    <ion-label color="text-1">{{'Home' | translate}}
    </ion-label>
    <gd-comp-icon [type]="'svg'" [name]="'icon-chevron-right'" size="18" [color]="'medium'">
    </gd-comp-icon>
  </div>

  <xng-breadcrumb [separator]="iconTemplate">
    <ng-container *xngBreadcrumbItem="let breadcrumb; let first = first; let last = last">
      <ng-container>
        <ion-label color="text-1" semi-bold-text>
          {{ breadcrumb }}
        </ion-label>
      </ng-container>
    </ng-container>

    <ng-template #iconTemplate>
      <gd-comp-icon id="separator-gdx" [type]="'svg'" [name]="'icon-chevron-right'" size="18" [color]="'medium'">
      </gd-comp-icon>
    </ng-template>
  </xng-breadcrumb>

</div>