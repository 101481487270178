import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbCustomService {
  constructor(public route: Router) { }

  getRouter() {
    if (this.route.url.includes('other')) {
      return false;
    } else {
      return true;
    }
  }
}
