import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { MESSAGES } from '@constants/index.constant';
import { AlertService } from '@services/lib/alert/alert.service';
import { LOGIN } from '../../../constants/libraries/routes';
import { NavigationService } from '../navigation/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class HandlingService {
  constructor(private alertService: AlertService, private navigationService: NavigationService) { }

  // hasProps<T, U extends string | number | symbol>(obj: T, ...propName: U[]): obj is T & { [P in U]: unknown } {
  //   return obj && propName.every((x) => x in obj);
  // }

  hasProps<T extends object, U extends (string | number | symbol)[]>(obj: T | null | undefined, ...propName: U): obj is T & { [P in U[number]]: unknown } {
    return obj !== null && obj !== undefined && propName.every((x) => x in obj);
  }

  async errorResponse(e: any) {
    if (e && this.hasProps(e, 'error')) {
      if (e.status === 401) {
        const confirm = await this.alertService.error(e.error.message);
        if (confirm) {
          Storage.clear();
          // window.location.reload();
          this.navigationService.navigateRoot(LOGIN);
        }
      } else {
        if (e.error) {
          if (e.error.message) {
            await this.alertService.error(e.error.message);
          } else {
            await this.alertService.error(MESSAGES.SOMETHING_WRONG);
          }
        } else {
          await this.alertService.error(e.message);
        }
      }
    } else if(this.hasProps(e, 'message')) {
      await this.alertService.error(e?.message);
    } else {
      if (e !== null) {
        await this.alertService.error(JSON.stringify(e));
      }
    }
    return;
  }
}
