import { Injectable } from '@angular/core';
import { AuthStorageService } from '@storage/auth/auth-storage.service';
import BaseUrlClass from './base-url.class';
import { BaseUrlTypeEnum } from './base-url.enum';
import { BehaviorSubject } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {environment as environmentDev} from "../../../../environments/environment.staging";
import {environment as environmentProd} from "../../../../environments/environment.prod";

@Injectable({
  providedIn: 'root',
})
export class BaseUrlService {
  protected instanceAuth: any;
  protected instanceAuth$ = new BehaviorSubject<any>(null)
  constructor(private authStorageService: AuthStorageService) { }

  set auth(auth: any) {
    this.instanceAuth = auth;
    this.instanceAuth$.next(auth)
  }

  get auth(): any {
    return this.instanceAuth;
  }

  async useBaseUrlService() {
    this.instanceAuth = await this.authStorageService.getBenefitAuth();
    this.instanceAuth$.next(this.instanceAuth);
  }

  getBaseUrl(baseUrlType: string) {
    return new Promise<string>((resolve, reject) => {

      let result: any;
      this.instanceAuth$.subscribe(data => {
        if (this.instanceAuth || data) {
          switch (baseUrlType) {
            case BaseUrlTypeEnum.MARKETPLACE:
              result = new BaseUrlClass(this.instanceAuth?.marketplace);
              break;
            case BaseUrlTypeEnum.GDSERVER:
              result = new BaseUrlClass(this.instanceAuth?.ist?.GOPATHNEW);
              break;
            case BaseUrlTypeEnum.GDSERVEROLD:
              result = new BaseUrlClass(this.instanceAuth?.ist?.GOPATH);
              break;
            case BaseUrlTypeEnum.TSMARKETPLACE:
              result = new BaseUrlClass(this.instanceAuth?.ist?.GOPATH + '/api');
              break;
            case BaseUrlTypeEnum.GDTRANSFER:
              result = new BaseUrlClass(this.instanceAuth?.greatdayTransfer);
              break;
            case BaseUrlTypeEnum.PPOB:
              result = new BaseUrlClass(this.instanceAuth?.greatdayPPOB);
              break;
            case BaseUrlTypeEnum.PAYROLL:
              result = new BaseUrlClass(this.instanceAuth?.payroll?.url);
              break;
            case BaseUrlTypeEnum.LOCAL3000:
              result = new BaseUrlClass('http://localhost:3000');
              break;
            case BaseUrlTypeEnum.BENEFITSSUPPORT:
              if (this.instanceAuth && !this.instanceAuth.benefitsSupport) {
                this.updateBenefitsSupportUrl();
              }
              result = new BaseUrlClass(this.instanceAuth?.benefitSupport);
          }
          if (result.baseUrl) {
            resolve(result.baseUrl);
          } else {
            console.log(`ERROR: Cannot determine BaseURL for ${baseUrlType}`)
            reject(null);
          }
        }
      });
    });
  }

  async getMarketplaceUrl() {
    return await this.getBaseUrl(BaseUrlTypeEnum.MARKETPLACE);
  }

  updateBenefitsSupportUrl() {
    let url = window.location.href;

    if (url.includes('localhost')) {
      this.instanceAuth = { ...this.instanceAuth, benefitSupport: environment.benefitSupport };
    } else if (url.includes('benefits-support-dev') || url.includes('staging')) {
      this.instanceAuth = { ...this.instanceAuth, benefitSupport: environmentDev.benefitSupport };
    } else {
      this.instanceAuth = { ...this.instanceAuth, benefitSupport: environmentProd.benefitSupport };
    }
  }
}
