import { Component, OnInit } from '@angular/core';
import { TokenService } from '@remotes/token/token.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  isLogin = false;
  constructor(private tokenService: TokenService) { }

  async ngOnInit() {
    await this.tokenService.useTokenService();
    const haveToken = this.tokenService.token;
    if (haveToken) {
      this.isLogin = true;
    }
  }
}
