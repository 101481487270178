import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MAIN_ROUTE } from '@constants/index.constant';
import { TokenService } from '@remotes/token/token.service';
import { NavigationService } from '@services/lib/navigation/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private tokenService: TokenService, private navigationService: NavigationService) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    await this.tokenService.useTokenService();
    if (!this.tokenService.haveToken()) {
      return true;
    }
    this.navigationService.navigateWithReplace(MAIN_ROUTE);
    return false;
  }
}
