export enum EHeaderTable {
  BENEFIT_OVERVIEW = 'BENEFIT_OVERVIEW',
  COMPANY_REGISTERED = 'COMPANY_REGISTERED',
  COMPANY_REGISTERED_TRANSACTION = 'COMPANY_REGISTERED_TRANSACTION',
  COMPANY_REGISTERED_TRANSACTION_DETAIL = 'COMPANY_REGISTERED_TRANSACTION_DETAIL',
  PAYOUT = 'PAYOUT',
  PAYOUT_TYPE = 'PAYOUT_TYPE',
  PENDING_PAYMENT_CA = 'PENDING_PAYMENT_CA',
  PENDING_PAYMENT_TOPUP_BILL = 'PENDING_PAYMENT_TOPUP_BILL',
  PENDING_PAYMENT_HISTORY = 'PENDING_PAYMENT_HISTORY',
  RAJABILLER_OVERVIEW = 'RAJABILLER_OVERVIEW',
  LIST_MEMBER = 'LIST_MEMBER',
  MASTER_SALES = 'MASTER_SALES',
  PERIOD_LIST = 'PERIOD_LIST',
  BILLING_REPORT_DETAIL = 'BILLING_REPORT_DETAIL',
  BILLING_REPORT = 'BILLING_REPORT',
  MONITORING_SALES = 'MONITORING_SALES',
  MONITORING_SALES_DETAIL = 'MONITORING_SALES_DETAIL',
  COMPANY_REGISTERED_COMMISSION = 'COMPANY_REGISTERED_COMMISSION',
  RESET_PIN = 'RESET_PIN',
  BILLING_SETTLEMENT = 'BILLING_SETTLEMENT',
  BANNER_MANAGEMENT = 'BANNER_MANAGEMENT',
  PAYMENT_SETTLEMENT = 'PAYMENT_SETTLEMENT',
  MANAGEMENT = 'MANAGEMENT',
  USER_VERIFICATION = 'USER_VERIFICATION',
  USER_VERIFICATION_HISTORY = 'USER_VERIFICATION_HISTORY',
  USER_VERIFICATION_AUTOMATIC = 'USER_VERIFICATION_AUTOMATIC',
  USER_APPROVAL_PROFILE = 'USER_APPROVAL_PROFILE',
  USER_PROFILE_HISTORY = 'USER_PROFILE_HISTORY',
  GREATDAY_LEADS = 'GREATDAY_LEADS',
  GREATDAY_LEADS_DETAIL = 'GREATDAY_LEADS_DETAIL',
  EMPLOYEE_PROFILE = 'EMPLOYEE_PROFILE',
  COMPANY_MANAGEMENT = 'COMPANY_MANAGEMENT',
  COMPANY_MANAGEMENT_SETUP_LIMIT = 'COMPANY_MANAGEMENT_SETUP_LIMIT',
  COMPANY_MANAGEMENT_HISTORY = 'COMPANY_MANAGEMENT_HISTORY',
  RESULT_FACE = 'RESULT_FACE',
  RESULT_FMS = 'RESULT_FMS',
  RESULT_DUKCAPIL = 'RESULT_DUKCAPIL',
  RESULT_VERIFICATION = 'RESULT_VERIFICATION',
  REPAYMENT_BILL = 'REPAYMENT_BILL',
  REPAYMENT_HISTORY = 'REPAYMENT_HISTORY',
  REPAYMENT_HISTORY_DETAIL = 'REPAYMENT_HISTORY_DETAIL',
  OUTBOND_LEADS = 'OUTBOND_LEADS',
  OUTBOND_HISTORY_DETAIL = 'OUTBOND_HISTORY_DETAIL',
  LIST_EMPLOYEE_PIC = 'LIST_EMPLOYEE_PIC',
}

export enum EHeaderSource {
  BENEFIT_OVERVIEW = 'assets/json/header-table/benefit-overview.json',
  COMPANY_REGISTERED = 'assets/json/header-table/company-registered.json',
  COMPANY_REGISTERED_TRANSACTION = 'assets/json/header-table/company-registered-transaction.json',
  COMPANY_REGISTERED_TRANSACTION_DETAIL = 'assets/json/header-table/company-registered-transaction-detail.json',
  PAYOUT = 'assets/json/header-table/payout.json',
  PAYOUT_TYPE = 'assets/json/header-table/payout-type.json',
  PENDING_PAYMENT_CA = 'assets/json/header-table/pending-payment-ca.json',
  PENDING_PAYMENT_TOPUP_BILL = 'assets/json/header-table/pending-payment-topup-bill.json',
  PENDING_PAYMENT_HISTORY = 'assets/json/header-table/pending-payment-history.json',
  RAJABILLER_OVERVIEW = 'assets/json/header-table/rajabiller-overview.json',
  LIST_MEMBER = 'assets/json/header-table/list-member.json',
  MASTER_SALES = 'assets/json/header-table/master-sales.json',
  PERIOD_LIST = 'assets/json/header-table/period-list.json',
  BILLING_REPORT_DETAIL = 'assets/json/header-table/billing-report-detail.json',
  BILLING_REPORT = 'assets/json/header-table/billing-report.json',
  MONITORING_SALES = 'assets/json/header-table/monitoring-sales.json',
  MONITORING_SALES_DETAIL = 'assets/json/header-table/monitoring-sales-detail.json',
  COMPANY_REGISTERED_COMMISSION = 'assets/json/header-table/company-registered-commission.json',
  RESET_PIN = 'assets/json/header-table/reset-pin.json',
  BILLING_SETTLEMENT = 'assets/json/header-table/billing-settlement.json',
  BANNER_MANAGEMENT = 'assets/json/header-table/banner-management.json',
  PAYMENT_SETTLEMENT = 'assets/json/header-table/payment-settlement.json',
  MANAGEMENT = 'assets/json/header-table/management.json',
  USER_VERIFICATION = 'assets/json/header-table/user-verification.json',
  USER_VERIFICATION_HISTORY = 'assets/json/header-table/user-verification-history.json',
  USER_VERIFICATION_AUTOMATIC = 'assets/json/header-table/user-verification-automatic.json',
  USER_APPROVAL_PROFILE = 'assets/json/header-table/user-approval-profile.json',
  USER_PROFILE_HISTORY = 'assets/json/header-table/user-profile-history.json',
  GREATDAY_LEADS = 'assets/json/header-table/greatday-leads.json',
  GREATDAY_LEADS_DETAIL = 'assets/json/header-table/greatday-leads-detail.json',
  EMPLOYEE_PROFILE = 'assets/json/header-table/employee-profile.json',
  COMPANY_MANAGEMENT = 'assets/json/header-table/company-management.json',
  COMPANY_MANAGEMENT_SETUP_LIMIT = 'assets/json/header-table/setup-limit.json',
  COMPANY_MANAGEMENT_HISTORY = 'assets/json/header-table/history.json',
  RESULT_FACE = 'assets/json/header-table/result-face.json',
  RESULT_FMS = 'assets/json/header-table/result-fms.json',
  RESULT_DUKCAPIL = 'assets/json/header-table/result-dukcapil.json',
  RESULT_VERIFICATION = 'assets/json/header-table/result-verification.json',
  REPAYMENT_BILL = 'assets/json/header-table/repayment-bill.json',
  REPAYMENT_HISTORY = 'assets/json/header-table/repayment-history.json',
  REPAYMENT_HISTORY_DETAIL = 'assets/json/header-table/repayment-history-detail.json',
  OUTBOND_LEADS = 'assets/json/header-table/outbond-leads.json',
  OUTBOND_HISTORY_DETAIL = 'assets/json/header-table/outbond-history-detail.json',
  LIST_EMPLOYEE_PIC = 'assets/json/header-table/list-employee-pic.json',
}
