import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { StoragePreferenceEnum, StoragePreferenceSpecDTO } from '../constant/storage.constant';

@Injectable({
  providedIn: 'root',
})
export class BaseStorageService {
  private static getStorageSpec(spec: StoragePreferenceEnum): StoragePreferenceSpecDTO {
    switch (spec) {
      case StoragePreferenceEnum.Auth:
        return { key: 'Auth' };
      case StoragePreferenceEnum.Saas:
        return { key: 'SFGoStorage' };
      case StoragePreferenceEnum.Language:
        return { key: 'language' };
      case StoragePreferenceEnum.SFGoAuth:
        return { key: 'SFGoAuth' };
      case StoragePreferenceEnum.BenefitAuthSupport:
        return { key: 'BenefitAuthSupport' };
      case StoragePreferenceEnum.StorePrelogin:
        return { key: 'StorePrelogin' };
      case StoragePreferenceEnum.GDTransferAuth:
        return { key: 'GDTransferAuth' };
      case StoragePreferenceEnum.TBLastTransaction:
        return { key: 'TBLastTransaction' };
      case StoragePreferenceEnum.CurrentCountry:
        return { key: 'CurrentCountry' };
      case StoragePreferenceEnum.UserRoles:
        return { key: 'UserRoles' };
    }
  }

  async getGreatDayStorage<T>(spec: StoragePreferenceEnum): Promise<T> {
    const storageSpect = BaseStorageService.getStorageSpec(spec);
    const { value } = await Storage.get({ key: storageSpect.key });
    const result = value && value !== 'undefined' ? JSON.parse(value) : '';
    return result as T;
  }

  async setGreatDayStorage(spec: StoragePreferenceEnum, value: any) {
    await Storage.set({
      key: BaseStorageService.getStorageSpec(spec).key,
      value: JSON.stringify(value),
    });
  }

  async removeGreatDayStorage(spec: StoragePreferenceEnum) {
    await Storage.remove({
      key: BaseStorageService.getStorageSpec(spec).key,
    });
  }

  async clearGreatDayStorage() {
    await Storage.clear();
  }
}
