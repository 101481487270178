import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BaseUrlService } from '@remotes/base-url/base-url.service';
import { TokenService } from '@remotes/token/token.service';
import { LanguageService } from '@services/lib/language/language.service';
import { NavigationService } from '@services/lib/navigation/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class AppGuard implements CanActivate {
  constructor(
    private tokenService: TokenService,
    private navigationService: NavigationService,
    private baseUrlService: BaseUrlService,
    private languageService: LanguageService,
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    await this.tokenService.useTokenService();
    if (this.tokenService.haveToken()) {
      await this.baseUrlService.useBaseUrlService();
      await this.languageService.useLanguageService();
      return true;
    }
    this.navigationService.navigateForward('/auth/login');
    return false;
  }
}
