import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseStorageService } from '@storage/base/base-storage.service';
import { StoragePreferenceEnum } from '@storage/constant/storage.constant';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private languages = [
    {
      value: 'en',
      text: 'English',
      locale: 'en',
    },
    {
      value: 'id',
      text: 'Indonesia',
      locale: 'in',
    },
  ];

  private instanceLanguage = 'en';

  constructor(private baseStorageService: BaseStorageService, private translateService: TranslateService) {}

  get languageOptions(): { value: string; text: string; locale: string }[] {
    return this.languages;
  }

  get currentLang(): string {
    return this.instanceLanguage ?? 'en';
  }

  set currentLang(lang: string) {
    this.instanceLanguage = lang;
    this.baseStorageService.setGreatDayStorage(StoragePreferenceEnum.Language, lang);
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
  }

  async useLanguageService() {
    const lang = await this.baseStorageService.getGreatDayStorage<string>(StoragePreferenceEnum.Language);
    if (lang) {
      this.instanceLanguage = lang;
      this.translateService.setDefaultLang(lang);
      this.translateService.use(lang);
    } else {
      this.instanceLanguage = 'en';
      this.baseStorageService.setGreatDayStorage(StoragePreferenceEnum.Language, 'en');
      this.translateService.setDefaultLang('en');
      this.translateService.use('en');
    }
  }

  async change(value: any) {
    return await firstValueFrom(this.translateService.get(value));
  }
}
