class BaseUrlClass {
  url: string;

  constructor(url: string) {
    this.url = url;
  }

  get baseUrl() {
    return this.url;
  }

  set setBaseUrl(value: string) {
    this.url = value;
  }
}

export default BaseUrlClass;
