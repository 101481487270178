import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from '@remotes/token/token.service';
import { Observable } from 'rxjs/internal/Observable';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BaseApiInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutValue = Number(req.headers.get('timeout')) || 60000;
    const isNest = req.headers.get('isNest');
    const token = this.tokenService.token;
    if (isNest === 'true') {
      req = req.clone({
        setHeaders: {
          Authorization: `${token ? token.id : null}`,
        },
      });
    }
    return next.handle(req).pipe(timeout(timeoutValue));
  }
}
