import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { MESSAGES } from '@constants/index.constant';
import { RequestAPI, ResponseData } from '@dto/index.dto';
import { MarketplaceApi } from '@remotes/apis/marketplace.api';
import { AlertService } from '@services/lib/alert/alert.service';
import { EncryptService } from '@services/lib/encrypt/encrypt.service';
import { HandlingService } from '@services/lib/handling/handling.service';
import { NavigationService } from '@services/lib/navigation/navigation.service';
import { SpinnerService } from '@services/lib/spinner/spinner.service';
import { AuthStorageService } from '@storage/auth/auth-storage.service';
import { BenefitsApi } from '@remotes/apis/benefits.api';

type ObjectType = {
  [key: string]: string;
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private marketplaceApi: MarketplaceApi,
    private encryptService: EncryptService,
    private handlingService: HandlingService,
    private authStorageService: AuthStorageService,
    private navigationService: NavigationService,
    private spinnerService: SpinnerService,
    private alertService: AlertService,
    private benefitsApi: BenefitsApi
  ) { }

  async login(email: string, password: string) {
    try {
      await this.spinnerService.showSpinner();

      const params = {
        email,
        password: this.encryptService.encrypt(password),
      };
      const request: RequestAPI = {
        model: 'BenefitSupportMember',
        func: 'login',
        params,
      };

      const { data } = await this.marketplaceApi.post(request);
      this.authStorageService.setBenefitSupportAuth(data);
      await this.spinnerService.hideSpinner();
      window.location.reload();
      this.navigationService.mainPage();
    } catch (e: unknown) {
      this.handlingService.errorResponse(e);
      await this.spinnerService.hideSpinner();
    }
  }

  async logout() {
    try {
      await this.spinnerService.showSpinner();
      const request: RequestAPI = {
        model: 'Auth',
        func: 'logout',
      };

      await this.benefitsApi.post(request);
      this.removeStorage();
      window.location.reload();
      this.navigationService.navigateRoot('auth/login');
      await this.spinnerService.hideSpinner();
    } catch (e: unknown) {
      this.handlingService.errorResponse(e);
      await this.spinnerService.hideSpinner();
    }
  }

  async forgotPassword(email: string) {
    try {
      await this.spinnerService.showSpinner();
      const request: RequestAPI = {
        model: 'Member',
        func: 'forgotPassword',
        params: {
          email,
        },
      };

      await this.benefitsApi.post(request);
      await this.spinnerService.hideSpinner();
      await this.alertService.success(MESSAGES.SUCCESSFULLY_SENT_TO_EMAIL);
      this.navigationService.navigateRoot('auth/login');
    } catch (e: unknown) {
      this.handlingService.errorResponse(e);
      await this.spinnerService.hideSpinner();
    }
  }

  async changePassword(payload: { password: string; newPassword: string; confirmPassword: string }) {
    try {
      if (payload.newPassword !== payload.confirmPassword) {
        this.alertService.error('The password confirmation does not match');
        throw new Error('The password confirmation does not match');
      }
      const params: ObjectType = { ...payload };
      for (const key of Object.keys(params)) {
        params[key] = this.encryptService.encrypt(params[key]);
      }
      const request: RequestAPI = {
        model: 'Member',
        func: 'changePassword',
        params,
      };

      await this.benefitsApi.post(request);
      Storage.clear();
      window.location.reload();
      this.navigationService.navigateRoot('auth/login');
    } catch (e: unknown) {
      this.handlingService.errorResponse(e);
    }
  }

  async switchCountry(code: string) {
    try {
      const params = {
        countryCode: code,
      };
      const request: RequestAPI = {
        model: 'Member',
        func: 'switchCountry',
        params,
      };

      await this.benefitsApi.post(request);
    } catch (e: unknown) {
      this.handlingService.errorResponse(e);
      await this.spinnerService.hideSpinner();
    }
  }

  async loginData(): Promise<ResponseData<any>> {
    try {
      const request: RequestAPI = {
        model: 'Member',
        func: 'loginData',
      };
      return await this.benefitsApi.get(request);
    } catch (error: any) {
      this.handlingService.errorResponse(error);
      await this.spinnerService.hideSpinner();
      return {
        message: error,
        data: null
      };
    }
  }

  async getCurrencyCode(countryCode = '') {
    if (countryCode === '') {
      const { data } = await this.loginData();
      countryCode = data?.userData?.currentCountryCode;
    }

    switch (countryCode.slice(0, 2)) { // Country code only has 2 characters
      case 'ID': // Indonesia
        return 'IDR';
      case 'PH': // Philippines
        return 'PHP';
      case 'TH': // Thailand
        return 'THB';
      default:
        // Default currency code for other countries or unknown country codes.
        return 'USD'; // Change this to the appropriate default currency code.
    }
  }

  async removeStorage() {
    localStorage.removeItem('CapacitorStorage.CurrentCountry');
    localStorage.removeItem('CapacitorStorage.UserRoles');
    localStorage.removeItem('CapacitorStorage.BenefitAuthSupport');
  }
}
