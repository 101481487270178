import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
@Pipe({ name: 'SFDate' })
export class SFFormatDatePipe implements PipeTransform {
  transform(content: any, type?: any): any {
    if (content && moment(content).isValid()) {
      try {
        // Date Now
        const dateNow = moment();
        // Date From DB
        const mindiff = new Date().getTimezoneOffset();
        const newContent = moment(content).add(mindiff, 'minutes').toISOString();
        let result: any;
        switch (type) {
          case 'timeattendance': {
            result = moment(content).format('HH:mm:ss');
            break;
          }
          case 'yyyy-MM-dd': {
            result = moment(newContent).format('YYYY-MM-DD');
            break;
          }
          case 'DD-MM-YYYYY': {
            result = moment(newContent).format('DD-MM-YYYY');
            break;
          }
          case 'dddDMMM': {
            result = moment(newContent).format('ddd, D MMM');
            break;
          }
          case 'MMMDYYYY': {
            result = moment(newContent).format('MMM, D YYYY');
            break;
          }
          case 'HHMM': {
            result = moment(newContent).format('HH:mm');
            break;
          }
          case 'HHMMs': {
            result = moment(content).format('HH:mm');
            break;
          }
          case 'HH:mm': {
            result = moment(newContent).format('HH:mm');
            break;
          }
          case 'HH:mm:ss': {
            result = moment(newContent).format('HH:mm:ss');
            break;
          }
          case 'DMMMYYYY': {
            result = moment(newContent).format('D MMM YYYY');
            break;
          }
          case 'dddDMMMYYYY': {
            result = moment(newContent).format('ddd, D MMM YYYY');
            break;
          }
          case 'ROOMCHAT': {
            const hours = dateNow.diff(moment(newContent), 'hours');
            if (hours < 24) {
              result = moment(newContent).fromNow();
            } else {
              result = moment(newContent).format('MMM, D YYYY HH:mm');
            }
            break;
          }
          case 'LISTCHAT': {
            const chathours = dateNow.diff(moment(content), 'hours');
            if (chathours < 24) {
              result = moment(content).fromNow();
            } else {
              result = moment(content).format('MM/DD/YY');
            }
            break;
          }
          case 'DAYGROUP': {
            result = moment(content).calendar(null, {
              sameDay: '[Today]',
              nextDay: '[Tomorrow]',
              nextWeek: 'dddd',
              lastDay: '[Yesterday]',
              lastWeek: '[Last] dddd',
              sameElse: 'DD/MM/YYYY',
            });
            break;
          }
          case 'LISTFEED': {
            const listfeed = dateNow.diff(moment(content), 'hours');
            if (listfeed < 24) {
              result = moment(content).fromNow();
            } else {
              result = moment(content).format('D MMM');
            }
            break;
          }
          case 'CalendarDetailDate': {
            result = moment(newContent).format('D MMM YY');
            break;
          }
          case 'CalendarDetailTime': {
            result = moment(newContent).format('HH:mm');
            break;
          }
          case 'CalendarDetailTimeZone': {
            const data = new Date(newContent).toString().match(/\((.*)\)/);
            result = data ? data.pop() : data;
            break;
          }
          case 'ddMMM': {
            result = moment(newContent).format('D MMM');
            break;
          }
          case 'ddMMMyyyyHHmm': {
            result = moment(content).format('D MMM YYYY HH:mm ');
            break;
          }
          case 'ddMMMyyyyHHmmNew': {
            result = moment(newContent).format('D MMM YYYY HH:mm');
            break;
          }
          case 'ddMMMyyyy': {
            result = moment(newContent).format('D MMM YYYY');
            break;
          }
          case 'ddMMMMyyyy': {
            result = moment(newContent).format('DD MMMM YYYY');
            break;
          }
          case 'ddMMMyyyysf6': {
            result = moment(content).format('D MMM YYYY');
            break;
          }
          case 'ddMMMMyyyysf6': {
            result = moment(content).format('D MMMM YYYY');
            break;
          }
          case 'ddMMMMsf6': {
            result = moment(content).format('D MMMM');
            break;
          }
          case 'dddDsf6': {
            result = moment(content).format('ddd, D MMM');
            break;
          }
          case 'ddMMMyy': {
            result = moment(newContent).format('D MMM YY');
            break;
          }
          case 'HH:mm|DDMMM':
            result = moment(newContent).format('HH:mm | DD MMM');
            break;
          case 'HH:mm|DDMMMYYYY':
            result = moment(newContent).format('HH:mm | DD MMM YYYY');
            break;
          case 'DDMMM':
            result = moment(newContent).format('DD MMM');
            break;
          case 'MMMDYYYYMoment': {
            result = moment(content).format('MMM, D YYYY');
            break;
          }
          case 'HHMMMoment': {
            result = moment(content).format('HH:mm');
            break;
          }
          case 'LISTDATE': {
            const hari = moment(dateNow).startOf('day').diff(moment(newContent).startOf('day'), 'days');
            const tahun = dateNow.diff(moment(newContent), 'years');
            if (hari === 0) {
              result = moment(newContent).format('HH:mm');
            } else if (tahun === 0) {
              result = moment(newContent).format('D MMM');
            } else {
              result = moment(newContent).format('D MMM YYYY');
            }
            break;
          }
          case 'LISTDATE2': {
            const hari3 = moment(dateNow).startOf('day').diff(moment(newContent).startOf('day'), 'days');
            const tahun3 = dateNow.diff(moment(newContent), 'years');
            if (hari3 === 0) {
              result = moment(newContent).format('HH:mm');
            } else if (tahun3 === 0) {
              result = moment(newContent).format('D MMM');
            } else {
              result = moment(newContent).format('D MMM YYYY');
            }
            break;
          }
          case 'LISTDATENEW': {
            const hari2 = moment(dateNow).startOf('day').diff(moment(newContent).startOf('day'), 'days');
            const tahun2 = dateNow.diff(moment(newContent), 'years');
            if (hari2 === 0) {
              result = moment(newContent).format('D MMM');
            } else if (tahun2 === 0) {
              result = moment(newContent).format('D MMM');
            } else {
              result = moment(newContent).format('D MMM YYYY');
            }
            break;
          }
          case 'LISTDATENEW2': {
            const hari5 = moment(dateNow).startOf('day').diff(moment(content).startOf('day'), 'days');
            const tahun5 = dateNow.diff(moment(content), 'years');
            if (hari5 === 0) {
              result = moment(content).format('D MMM');
            } else if (tahun5 === 0) {
              result = moment(content).format('D MMM');
            } else {
              result = moment(content).format('D MMM YYYY');
            }
            break;
          }
          case 'MMYYYY': {
            result = moment(newContent).format('MMM YYYY');
            break;
          }
          case 'DATEAPPROVAL': {
            /** tolong jangan diubah ke newContent karena dipakai untuk jam sf6 */
            const hariapp = moment(dateNow).startOf('day').diff(moment(content).startOf('day'), 'days');
            const tahunapp = dateNow.diff(moment(content), 'years');
            if (hariapp === 0) {
              result = moment(content).format('HH:mm');
            } else if (tahunapp === 0) {
              result = moment(content).format('D MMM');
            } else {
              result = moment(content).format('D MMM YYYY');
            }
            break;
          }
          case 'DDMMYYYY': {
            result = moment(newContent).format('DD/MM/YYYY');
            break;
          }
          case 'MMDDYYYY': {
            result = moment(newContent).format('MM/DD/YYYY');
            break;
          }
          case 'LISTSOCIAL': {
            const dateSocial = moment(content).add(-420, 'minutes').toISOString();
            const listsocial = dateNow.diff(moment(dateSocial), 'hours');
            if (listsocial < 24) {
              result = moment(dateSocial).fromNow();
            } else {
              result = moment(dateSocial).format('D MMM Y');
            }
            break;
          }
          case 'LISTARCHIVED': {
            result = moment(newContent).format('D MMM, HH:mm');
            break;
          }
          case 'TASKFEEDBACKDATE': {
            const taskfeedback = dateNow.diff(moment(newContent), 'hours');
            if (taskfeedback < 24) {
              result = moment(newContent).format('HH:mm');
            } else {
              result = moment(newContent).format('D MMM');
            }
            break;
          }
          case 'MMMyyyy': {
            result = moment(newContent).format('MMM YYYY');
            break;
          }
          case 'H:mm, d/M/y': {
            result = moment(newContent).format('HH:mm, DD/MM/YYYY');
            break;
          }
          case 'hhmmsf6': {
            result = moment(content).format('HH:mm');
            break;
          }
          case 'HHmmN': {
            content = content.replace('1899', '2013');
            const timeOffset = new Date().getTimezoneOffset();
            const context = moment(content).set('year', 2013).add(timeOffset, 'minutes').toISOString();
            result = moment(context).format('HH:mm');
            break;
          }
          case 'DMMMYYYYN': {
            if (typeof content === 'string' || content instanceof String) {
              result = moment(newContent).format('D MMM YYYY');
            } else {
              result = '';
            }
            break;
          }
          case 'HH:mm:chat': {
            result = moment(content).format('HH:mm');
            break;
          }
          case 'HOMEFEEDTASK': {
            const dateTask = dateNow.diff(moment(newContent), 'hours');
            if (dateTask < 24) {
              result = moment(newContent).fromNow();
            } else {
              result = moment(newContent).format('D MMM YYYY');
            }
            break;
          }
          case 'HOMEFEEDTASKHHmm': {
            result = moment(content).utc().format('HH:mm');
            break;
          }
          case 'ThermalDate': {
            result = moment(content).format('D MMM YYYY');
            break;
          }
          case 'ThermalDateTime': {
            result = moment(content).format('MMM, D YYYY HH:mm');
            break;
          }
          case 'NEWDATEAPPROVAL': {
            const hariapp2 = moment(dateNow).startOf('day').diff(moment(newContent).startOf('day'), 'days');
            const tahunapp2 = dateNow.diff(moment(newContent), 'years');
            if (hariapp2 === 0) {
              result = moment(newContent).format('HH:mm');
            } else if (tahunapp2 === 0) {
              result = moment(newContent).format('D MMM');
            } else {
              result = moment(newContent).format('D MMM YYYY');
            }
            break;
          }
          default:
            result = moment(newContent).format('MMM, D YYYY HH:mm');
        }
        return result;
      } catch (error) {
        return '';
      }
    } else {
      return '';
    }
  }
}
