import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BreadcrumbPipe } from './breadcrumb.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [BreadcrumbPipe],
  exports: [BreadcrumbPipe],
  providers: [],
})
export class PipesModule {}
