import { Injectable } from '@angular/core';
import { IBenefitLoginResponse } from '@dto/index.dto';
import { AuthStorageService } from '@storage/auth/auth-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  protected instanceToken: any = null;
  constructor(private authStorageService: AuthStorageService) { }

  set token(token: IBenefitLoginResponse) {
    this.instanceToken = token;
  }

  get token(): IBenefitLoginResponse {
    if (!this.instanceToken) {
      this.useTokenService();
    }
    return this.instanceToken;
  }

  async useTokenService() {
    const benefitResponse = await this.authStorageService.getBenefitAuth();
    if (benefitResponse) {
      this.instanceToken = benefitResponse;
    } else {
      this.instanceToken = null;
    }
  }

  haveToken(): boolean {
    if (this.instanceToken !== null) {
      return true;
    }
    return false;
  }
}
