import { Component, HostListener, OnInit } from '@angular/core';
import { IListType } from '@dto/index.dto';
import { GdCompAlertControllerService } from '@greatday/components';
import { TokenService } from '@remotes/token/token.service';
import { AuthService } from '@services/bloc/auth/auth.service';
import { AlertService } from '@services/lib/alert/alert.service';
import { LanguageService } from '@services/lib/language/language.service';
import { NavigationService } from '@services/lib/navigation/navigation.service';
import { SpinnerService } from '@services/lib/spinner/spinner.service';
import { AuthStorageService } from '@storage/auth/auth-storage.service';
import { BaseStorageService } from '@storage/base/base-storage.service';
import { StoragePreferenceEnum } from '@storage/constant/storage.constant';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  toggleHeaderPopover = false;
  userData: any = {};
  country = { name: 'Indonesia', value: 'ID' };
  countries: IListType[] = [];
  storageBenefit: any;
  countryLoginData: string;
  countryNew: any;
  data: any;

  public menuPopover = [
    {
      iconUrl: 'icon-lock',
      label: 'Change Password',
      id: 'changePassword',
      show: true,
    },
    {
      iconUrl: 'icon-log-in',
      label: 'Logout',
      id: 'logout',
      show: true,
    },
  ];

  constructor(
    private navigationService: NavigationService,
    private tokenService: TokenService,
    private authService: AuthService,
    public languageService: LanguageService,
    public gdCompAlertControllerService: GdCompAlertControllerService,
    private alertService: AlertService,
    private spinnerService: SpinnerService,
    private baseStorageService: BaseStorageService,
    private authStorageService: AuthStorageService,
  ) { }

  async ngOnInit() {
    await this.tokenService.useTokenService();
    this.userData = this.tokenService.token?.userData;
    if (this.userData) {
      await this.getLoginData();
    }
    this.storageBenefit = await this.authStorageService.getBenefitAuth();

    const currentCountries: any = await this.baseStorageService.getGreatDayStorage(
      StoragePreferenceEnum.CurrentCountry,
    );

    if (currentCountries && currentCountries.length > 0) {
      const newArr = currentCountries?.filter((values: any) => {
        return values.value === this.countryLoginData;
      });
      this.countryNew = newArr;
      this.country = newArr[0];

      await this.current();
    } else {
      console.log('Countries not found')
    }

    this.baseStorageService.setGreatDayStorage(StoragePreferenceEnum.BenefitAuthSupport, this.data);
    this.countries = await this.baseStorageService.getGreatDayStorage(StoragePreferenceEnum.CurrentCountry);
  }

  backToHome() { }

  openPage(id: string) {
    switch (id) {
      case 'logout':
        this.logout();
        break;
      case 'changePassword':
        this.navigationService.navigateForward('/change-password');
        break;
    }
  }
  async getLoginData() {
    const { data } = await this.authService.loginData();
    this.data = data;
    this.countryLoginData = data?.userData?.currentCountryCode;
  }

  async current() {
    const current: any = await this.baseStorageService.getGreatDayStorage(StoragePreferenceEnum.CurrentCountry);
    if (current) {
      const data = current.filter((q: any) => {
        return q.value === this.countryNew[0].value;
      });
      const currentCountries = this.data.userData.countries.map((item: any) => {
        return {
          name: item.countryName,
          value: item.countryCode,
        };
      });
      const index = currentCountries.filter((values: any) => {
        return values.value === data[0].value;
      });
      currentCountries.splice(
        currentCountries.findIndex((e: any) => e.value === data[0].value),
        1,
      );
      const valueCoutries = [...currentCountries, ...index].reverse();
      await this.baseStorageService.setGreatDayStorage(StoragePreferenceEnum.CurrentCountry, valueCoutries);
    }
  }

  async logout() {
    this.toggleHeaderPopover = !this.toggleHeaderPopover;
    await this.authService.logout();
  }

  profileToggle() {
    this.toggleHeaderPopover = !this.toggleHeaderPopover;
  }

  async openLanguage() {
    const data = this.languageService.languageOptions.map((element) => {
      return {
        name: element.text,
        value: element.value,
      };
    });
    const alert = await this.gdCompAlertControllerService.create({
      inputs: [
        {
          type: 'radio',
          value: this.languageService.currentLang,
          data,
          attributes: {
            rows: 1,
          },
        },
      ],
      submitButton: {
        label: 'Ok',
        role: 'submit',
        color: 'primary',
        handler: (e: any) => {
          this.languageService.currentLang = e[0].value;
        },
      },
      cancelButton: {
        label: 'Cancel',
        role: 'cancel',
        handler: () => { },
      },
    });
    await alert.present();
  }

  async openSwitchAccount() {
    const alert = await this.gdCompAlertControllerService.create({
      inputs: [
        {
          type: 'radio',
          value: this.country.value,
          data: this.countries,
          attributes: {
            rows: 1,
          },
        },
      ],
      submitButton: {
        label: 'Ok',
        role: 'submit',
        color: 'primary',
        handler: async (e: any) => {
          // reorder countries, cause the first array is primary
          const index = this.countries.findIndex((x) => x.value === e[0].value);
          this.country = this.countries[index];
          this.moveInArray(this.countries, index);
          await this.spinnerService.showSpinner();

          setTimeout(async () => {
            this.baseStorageService.setGreatDayStorage(StoragePreferenceEnum.CurrentCountry, this.countries);
            await this.authService.switchCountry(this.country.value);
            this.storageBenefit.userData.currentCountryCode = this.countryLoginData;
            this.authStorageService.setBenefitSupportAuth(this.storageBenefit);
            await this.spinnerService.hideSpinner();
            await this.alertService.success('Successfully switch to another account');
            window.location.reload();
          }, 3000);
        },
      },
      cancelButton: {
        label: 'Cancel',
        role: 'cancel',
        handler: () => { },
      },
    });
    await alert.present();
  }

  moveInArray(arr: any[], from: number, to = 0) {
    if (Object.prototype.toString.call(arr) !== '[object Array]') {
      throw new Error('Please provide a valid array');
    }
    const item = arr.splice(from, 1);
    if (!item.length) {
      throw new Error('There is no item in the array at index ' + from);
    }
    arr.unshift(item[0]);
  }

  @HostListener('window:click', ['$event'])
  clickout(event: any) {
    if (event.target.id !== 'navbar-profile') {
      this.toggleHeaderPopover = false;
    }
  }
}
