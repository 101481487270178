import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GdCompIconModule } from '@greatday/components';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@pipes/pipes.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { AppBreadcrumbComponent } from './app-breadcrumb.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FlexLayoutModule,
    BreadcrumbModule,
    AngularSvgIconModule,
    GdCompIconModule,
    TranslateModule,
    PipesModule,
  ],
  exports: [AppBreadcrumbComponent],
  declarations: [AppBreadcrumbComponent],
})
export class AppBreadcrumbComponentModule { }
