import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TitleCasePipe } from '@angular/common';

@Pipe({
  name: 'breadcrumb',
})
export class BreadcrumbPipe implements PipeTransform {
  constructor(private translateService: TranslateService, private titleCasePipe: TitleCasePipe) {}

  transform(value: any, ...args: unknown[]): unknown {
    if (['other'].some((v) => value.includes(v))) {
      const separator = document.querySelectorAll('#separator-gdx');
      separator.forEach((element) => {
        (element as HTMLElement).style.display = 'none';
      });
      return '';
    } else {
      let replaceStrip: any = value.replace('-', ' ');

      while (replaceStrip.includes('-')) {
        replaceStrip = replaceStrip.replace('-', ' ');
      }
      let replaceUnderscore: string = replaceStrip.replace('_', ' ');
      while (replaceUnderscore.includes('-')) {
        replaceUnderscore = replaceUnderscore.replace('_', ' ');
      }

      const capitalize = this.titleCasePipe.transform(replaceUnderscore);
      return this.translateService.instant(capitalize);
    }
  }
}
