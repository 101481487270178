export enum BaseUrlTypeEnum {
  GDSERVER = 'gdserver',
  GDSERVEROLD = 'gdserverold',
  MARKETPLACE = 'marketplace',
  GDTRANSFER = 'gdtransfer',
  PAYROLL = 'payroll',
  TSMARKETPLACE = 'tsmarketplace',
  PPOB = 'ppob',
  CERMATI = 'cermati',
  LOCAL3000 = 'local',
  BENEFITSSUPPORT = 'benefitsSupport',
}
