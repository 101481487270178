export enum ECountryCode {
  ID = 'ID',
  MY = 'MY',
  PH = 'PH',
  TH = 'TH'
}

export enum ECurrencyCode {
  ID = 'IDR', // INDONESIAN RUPIAH
  MY = 'MYR', // MALAYSIAN RINGGIT
  PH = 'PHP', // PHILIPPINE PESO
  TH = 'THB'  // Thai Baht
}

export enum ECurrencySymbol {
  IDR = 'Rp',
  MYR = 'RM',
  PHP = '₱',
  THB = '฿'
}
