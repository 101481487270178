import { Component } from '@angular/core';
import { BreadcrumbCustomService } from '@services/lib/breadcrumb/breadcrumb-custom.service';
import { NavigationService } from '@services/lib/navigation/navigation.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app-breadcrumb.component.html',
  styleUrls: ['./app-breadcrumb.component.scss'],
})
export class AppBreadcrumbComponent {
  constructor(public breadcrumb: BreadcrumbCustomService, private navigationService: NavigationService) { }

  toHome() {
    this.navigationService.navigateForward('generic/company-registered');
  }
}
