// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration production` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  benefitSecretKey: 'BENaZrWnTpX6qBCK2TjMO1ekQE9qZPgqIJN',
  cermatihost: 'https://stg1.cermati.com/api',
  ppob: 'https://integration.greatdayhr.com/ppobdev',
  marketplace: 'https://api.greatdayhr.com/marketplacedev2',
  sass: 'https://saas.greatdayhr.com/getAPIConfig?account=',
  sass2:
    'https://saas3.dataon.com/sf6svc/SFCSunFish.cfm?PROVIDER=GetAPIConfig&STRAUTHKEY=9C1C5414B09EE2C0A7925C3FCC7CD25DBF54ECEDBB90BBFDA5C84F1F4CC660B95B6B3AFF2DFB05CC577B12A2ECD8072099BFF8D0CC7A0CAB3B985735&',
  sass3:
    'https://saas.dataon.com/sf6svc/SFCSunFish.cfm?PROVIDER=GetAPIConfig&STRAUTHKEY=9C1C5414B09EE2C0A7925C3FCC7CD25DBF54ECEDBB90BBFDA5C84F1F4CC660B95B6B3AFF2DFB05CC577B12A2ECD8072099BFF8D0CC7A0CAB3B985735&',
  benefitSupport: 'https://benefits-server-dev.greatdayhr.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
