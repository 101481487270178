export enum RouteEnum {
  BENEFIT_SETTING = '/benefit-setting',
  BILLING = '/billing',
  BLOCK_USER = '/block-user',
  DATA_SYNC = '/data-synchronization',
  HOME = '/home',
  INTERNAL_PLAN = '/internal-plan',
  TEAM_MEMBER = '/team-member',
  TRANSACTION_REPORT = '/transaction-report',
  LOGIN = 'auth/login/login'
}

export const MAIN_ROUTE = 'benefits/benefits-summary';

export const LOGIN = 'auth/login/login'
