import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IntegerService {
  constructor() { }

  formatRupiah(angka: number, prefix?: string) {
    const numberString = angka.toString().replace(/[^,\d]/g, '');
    const split = numberString.split(',');
    const sisa = split[0].length % 3;
    let rupiah = split[0].substr(0, sisa);
    const ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      const separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix === undefined ? `Rp ${rupiah}` : rupiah ? prefix + rupiah : '';
  }

  formatRinggit(value: string, prefix?: string) {
    const split = value.split('.');
    const numberString = split[0].toString().replace(/[^,\d]/g, '')
    const sisa = numberString.length % 3;
    let ringgit = numberString.substr(0, sisa);
    const ribuan = numberString.substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      const separator = sisa ? ',' : '';
      ringgit += separator + ribuan.join(',');
    }

    ringgit = split[1] !== undefined ? ringgit + '.' + split[1] : ringgit;
    return prefix === undefined ? `RM ${ringgit}` : ringgit ? prefix + ringgit : '';
  }

  formatPeso(value: string, prefix?: string) {
    const split = value.split('.');
    const numberString = split[0].toString().replace(/[^,\d]/g, '')
    const sisa = numberString.length % 3;
    let peso = numberString.substr(0, sisa);
    const ribuan = numberString.substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      const separator = sisa ? ',' : '';
      peso += separator + ribuan.join(',');
    }

    peso = split[1] !== undefined ? peso + '.' + split[1] : peso;
    return prefix === undefined ? `PHP ${peso}` : peso ? prefix + peso : '';
  }

  formatBaht(value: string, prefix = "฿") {
    const split = value.split('.');
    const numberString = split[0].toString().replace(/[^,\d]/g, '');
    const sisa = numberString.length % 3;
    let baht = numberString.substr(0, sisa);
    const ribuan = numberString.substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      const separator = sisa ? ',' : '';
      baht += separator + ribuan.join(',');
    }

    baht = split[1] !== undefined ? baht + '.' + split[1] : baht;
    return prefix === undefined ? `฿ ${baht}` : baht ? prefix + baht : '';
  }
}
